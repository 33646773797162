import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import Button from '../Button';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import images from '../GalleryPage/images';
import s from '../Gallery/index.module.css';

export default function Gallery() {
  const { t } = useTranslation();

  return (
    <div>
      <Header />
      <div className={s.Wrap}>
        <h1 className={s.Title}>{t('gallery.title')}</h1>
        <div className={s.catalogPage}>
          {images.map((image) => (
            <div className={s.catalogCard} key={image.id}>
              <img src={image.src} alt={t(image.titleKey)} />
              <h2>{t(image.titleKey)}</h2>
              <Link to={`/gallery/${image.id}`}>
                <Button text={t('gallery.button')} />
              </Link>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}
