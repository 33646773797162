import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import localeUK from './uk.json';
import localeEn from './en.json';

i18n.use(initReactI18next).init({
  debug: true,
  lng: 'uk',
  fallbackLng: 'uk',
  supportedLngs: ['uk', 'en'],
  defaultNS: 'common',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    uk: { common: localeUK },
    en: { common: localeEn },
  },
});

export default i18n;
