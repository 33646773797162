import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import photo from '../../images/AboutUsImg.png';

import s from '../AboutUs/index.module.css';

export default function AboutUs() {
  const { t } = useTranslation();

  return (
    <div className={classNames('container', s.container)}>
      <h2 className={s.aboutTitle}>{t('aboutUs.title')}</h2>
      <div className={s.aboutSection}>
        <div className={s.aboutImg}>
          <img src={photo} alt="AboutUsImg" />
        </div>
        <div className={s.aboutText}>
          <div className={s.text}>{t('aboutUs.text')}</div>
          <div className={s.text}>{t('aboutUs.textNext')}</div>
          <div className={s.text}>{t('aboutUs.production')}</div>
        </div>
      </div>
    </div>
  );
}
