import React from 'react';
import s from '../GalleryPage/index.module.css';

const ImageVariants = ({
  variants,
  onVariantChange,
  baseColor,
  selectedVariant,
}) => {
  return (
    <div className={s.variantSelector}>
      <label className={s.variantLabel}>
        <input
          type="radio"
          name="baseColor"
          checked={selectedVariant === null}
          onChange={() => onVariantChange(null)}
          className={s.variantInput}
        />
        <span
          className={s.variantCircle}
          style={{ backgroundColor: baseColor }}
        />
      </label>

      {variants.map((variant, index) => (
        <label key={index} className={s.variantLabel}>
          <input
            type="radio"
            name="variant"
            checked={selectedVariant === index}
            onChange={() => onVariantChange(index)}
            className={s.variantInput}
          />
          <span
            className={s.variantCircle}
            style={{ backgroundColor: variant.color }}
          />
        </label>
      ))}
    </div>
  );
};

export default ImageVariants;
