import React, { useState, useRef, useEffect } from 'react';
import { ReactComponent as Home } from '../../images/mobIcons/home.svg';
import { ReactComponent as List } from '../../images/mobIcons/list.svg';
import { ReactComponent as Phone } from '../../images/mobIcons/phone.svg';
import { Radio } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import logo from '../../images/logo.png';
import s from '../Header/index.module.css';

export default function Header() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const menuRef = useRef(null);

  const plainOptions = ['EN', 'UK'];

  const onChangeLg = (language) => {
    i18n.changeLanguage(language.toLowerCase());
  };

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (menuOpen && menuRef.current && !menuRef.current.contains(e.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen]);

  return (
    <>
      <button className={s.menuButton} onClick={toggleMenu}>
        {Array(3)
          .fill(null)
          .map((_, i) => (
            <span key={i} className={s.bar}></span>
          ))}
      </button>

      <div
        ref={menuRef}
        className={classNames(s.headerWrap, { [s.active]: menuOpen })}
      >
        <div className={classNames('container', s.container)}>
          <div className={s.leftSide}>
            <img onClick={() => navigate('/')} alt="logo_icon" src={logo} />
            <div className={s.routeBtn}>
              <a href="tel:+3800964805551" className={s.mobNumber}>
                <Phone className={s.icon} />
                +380 (096) 480-55-51
              </a>
              <button
                onClick={() => navigate('/')}
                className={classNames(
                  { [s.active]: pathname === '/' },
                  s.navigateBtn
                )}
                type="button"
              >
                <Home className={s.icon} />
                {t('header.homePage')}
              </button>
              <button
                onClick={() => navigate('/gallery')}
                className={classNames(
                  { [s.active]: pathname === '/gallery' },
                  s.navigateBtn
                )}
                type="button"
              >
                <List className={s.icon} />
                {t('header.gallery')}
              </button>
            </div>
          </div>
          <div className={s.language}>
            <a className={s.descMob} href="tel:+3800964805551">
              +380 (096) 480-55-51
            </a>

            <Radio.Group
              onChange={(e) => onChangeLg(e.target.value)}
              className={s.radioGroup}
              options={plainOptions}
              optionType="button"
              buttonStyle="solid"
            />
          </div>
        </div>
      </div>

      <div className={s.mobileWrap}>
        <img onClick={() => navigate('/')} alt="logo_icon" src={logo} />
      </div>
    </>
  );
}
