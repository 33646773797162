import React from 'react';

import HomePage from './components/HomePage';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import GalleryPage from './components/GalleryPage';

import Gallery from './components/Gallery';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/gallery/:id" element={<GalleryPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
