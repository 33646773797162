import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import banner from '../../images/banner.png';

import s from '../Banner/index.module.css';
import Button from '../Button';

export default function Banner() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={classNames('container', s.container)}>
      <div className={s.bannerImg}>
        <img src={banner} alt="banner" />
      </div>
      <div className={s.bannerText}>
        <h1 className={s.descTitle}>{t('banner.title')}</h1>
        <h3 className={s.descText}>{t('banner.text')}</h3>
        <h1 className={s.mobTitle}>{t('banner.mobTitle')}</h1>
        <h1 className={s.mobPostTitle}>{t('banner.mobPostTitle')}</h1>
        <h3 className={s.mobText}>{t('banner.mobText')}</h3>
        <Button
          onClick={() => navigate('/gallery')}
          className={s.btn}
          text={t('header.gallery')}
        />
      </div>
    </div>
  );
}
