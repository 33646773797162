import Advantages from '../Advantages';
import AboutUs from '../AboutUs';
import Banner from '../Banner';
import Header from '../Header';
import Footer from '../Footer';

export default function HomePage() {
  return (
    <div>
      <Header />
      <Banner />
      <AboutUs />
      <Advantages />
      <Footer />
    </div>
  );
}
