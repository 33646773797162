import React from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from '../Button';
import s from '../Advantages/index.module.css';

export default function Advantages() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Замість зберігання вже перекладеного тексту,
  // зберігаємо ключі перекладу.
  const advantages = [
    { number: '2000+', titleKey: 'advantages.firstText' },
    { number: '10+', titleKey: 'advantages.secondText' },
    { number: '15+', titleKey: 'advantages.thirdText' },
    { number: '100%', titleKey: 'advantages.fourText' },
  ];

  return (
    <div className={classNames('container', s.container)}>
      <h2>{t('advantages.title')}</h2>

      <div className={s.advList}>
        {advantages.map((advantage) => (
          <div className={s.advSection} key={advantage.number}>
            <span className={s.number}>{advantage.number}</span>
            <span className={s.text}>{t(advantage.titleKey)}</span>
          </div>
        ))}
      </div>
      <Button
        onClick={() => navigate('/gallery')}
        className={s.btn}
        text={t('header.gallery')}
      />
    </div>
  );
}
