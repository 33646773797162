import React from 'react';

import i18n from '../../language';
import s from '../Footer/index.module.css';

import classNames from 'classnames';

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div className={s.wrap}>
      <div className={classNames('container', s.container)}>
        <div className={s.infoSection}>
          <h2>{i18n.t('footer.workingHours')}</h2>
          <span>{i18n.t('footer.days')}: 08:00-17:00</span>
          <span>{i18n.t('footer.saturday')}: 08:00-13:00</span>
          <span>{i18n.t('footer.dayOff')}</span>
        </div>
        <div className={s.infoSection}>
          <h2>{i18n.t('footer.contactTitle')}:</h2>
          <span>{i18n.t('footer.address')}</span>
          <a href="tel:+3800964805551" className={s.mobNumber}>
            +380 (096) 480-55-51
          </a>
          <a href="mailto:elitniygrob@gmail.com" className={s.emailLink}>
            elitniygrob@gmail.com
          </a>
        </div>
      </div>
      <div className={s.year}>
        <span>{currentYear}</span>
      </div>
    </div>
  );
}
