const images = [
  {
    id: 1,
    src: '/images/catalog/1.png',
    titleKey: 'gallery.one',
    descriptionKey: 'galleryPage.oneDesc',
  },
  {
    id: 2,
    src: '/images/catalog/2.png',
    titleKey: 'gallery.two',
    descriptionKey: 'galleryPage.twoDesc',
    color: '#5C1D2B',
    variant: [
      {
        color: '#CBE5FE',
        src: '/images/radioImg/2alt.png',
      },
    ],
  },
  {
    id: 3,
    src: '/images/catalog/3.png',
    titleKey: 'gallery.three',
    descriptionKey: 'galleryPage.threeDesc',
  },
  {
    id: 4,
    src: '/images/catalog/4.png',
    titleKey: 'gallery.four',
    descriptionKey: 'galleryPage.fourDesc',
    color: '#431213',
    variant: [
      {
        color: '#BD571E',
        src: '/images/radioImg/4alt.png',
      },
    ],
  },
  {
    id: 5,
    src: '/images/catalog/5.png',
    titleKey: 'gallery.five',
    descriptionKey: 'galleryPage.fiveDesc',
    color: '#3F0402',
    variant: [
      {
        color: '#A46130',
        src: '/images/radioImg/5alt.png',
      },
    ],
  },
  {
    id: 6,
    src: '/images/catalog/6.png',
    titleKey: 'gallery.six',
    descriptionKey: 'galleryPage.sixDesc',
    color: '#6C1609',
    variant: [
      {
        color: '#8C3B2F',
        src: '/images/radioImg/6alt.png',
      },
    ],
  },
  {
    id: 8,
    src: '/images/catalog/8.png',
    titleKey: 'gallery.eight',
    descriptionKey: 'galleryPage.eightDesc',
    color: '#70464A',
    variant: [
      {
        color: '#773112',
        src: '/images/radioImg/8alt.png',
      },
    ],
  },
  {
    id: 12,
    src: '/images/catalog/12.png',
    titleKey: 'gallery.twelve',
    descriptionKey: 'galleryPage.twelveDesc',
    color: '#631D2D',
    variant: [
      {
        color: '#AF6442',
        src: '/images/radioImg/12alt.png',
      },
    ],
  },
  {
    id: 13,
    src: '/images/catalog/12xl.png',
    titleKey: 'gallery.twelveXL',
    descriptionKey: 'galleryPage.twelveXlDesc',
  },
  {
    id: 14,
    src: '/images/catalog/13.png',
    titleKey: 'gallery.thirteen',
    descriptionKey: 'galleryPage.thirteenDesc',
    color: '#833F2B',
    variant: [
      {
        color: '#A9511A',
        src: '/images/radioImg/13alt.png',
      },
    ],
  },
  {
    id: 15,
    src: '/images/catalog/13xl.png',
    titleKey: 'gallery.thirteenXL',
    descriptionKey: 'galleryPage.thirteenXlDesc',
  },
  {
    id: 16,
    src: '/images/catalog/altar.png',
    titleKey: 'gallery.altar',
    descriptionKey: 'galleryPage.altarDesc',
  },
  {
    id: 17,
    src: '/images/catalog/sarcofag.png',
    titleKey: 'gallery.sarcofag',
    descriptionKey: 'galleryPage.sarcofagDesc',
  },
];

export default images;
