import React from 'react';

import s from '../Button/index.module.css';

export default function Button({ text, onClick }) {
  return (
    <button onClick={onClick} className={s.button}>
      {text}
    </button>
  );
}
