import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import images from './images';
import Header from '../Header';
import Footer from '../Footer';
import Button from '../Button';
import ImageVariants from './ImageVariant';
import classNames from 'classnames';
import s from '../GalleryPage/index.module.css';
import { useTranslation } from 'react-i18next';

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowWidth;
};

const GalleryPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const windowWidth = useWindowWidth();
  const imageId = parseInt(id, 10);
  const image = images.find((img) => img.id === imageId);

  if (!image) {
    return <div>Not found</div>;
  }

  const isSliderPage = [16, 17].includes(imageId);

  const hasVariants = image.variant && image.variant.length > 0;
  const [selectedVariantIndex, setSelectedVariantIndex] = useState(null);

  const [activeTab, setActiveTab] = useState('photo');
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const displayedSrc =
    selectedVariantIndex === null
      ? image.src
      : image.variant[selectedVariantIndex]?.src;

  const renderSlider = () => (
    <Splide
      options={{ type: 'loop', autoplay: true, interval: 3000 }}
      className={s.slider}
    >
      <SplideSlide>
        <img className={s.img} src={image.src} alt={t(image.titleKey)} />
      </SplideSlide>
      <SplideSlide>
        <img
          className={s.img}
          src={
            imageId === 16
              ? '/images/altarOpen.png'
              : '/images/sarcofagOpen.png'
          }
          alt={`Another slide for ID ${imageId}`}
        />
      </SplideSlide>
    </Splide>
  );

  return (
    <div>
      <Header />
      <div className={s.navigate} onClick={() => navigate('/gallery')}>
        {t('header.gallery')}-{' '}
        <div className={s.navigateAfter}>{t(image.titleKey)}</div>
      </div>

      <div className={classNames('container', s.container)}>
        <div className={s.section}>
          {windowWidth <= 650 && (
            <h1 className={s.title}>{t(image.titleKey)}</h1>
          )}

          {isSliderPage && windowWidth <= 650 && (
            <div className={s.tabButtons}>
              <button
                onClick={() => setActiveTab('photo')}
                className={classNames(s.tabButton, {
                  [s.activeTab]: activeTab === 'photo',
                })}
              >
                {t('galleryPage.mobPhoto')}
              </button>
              <button
                onClick={() => setActiveTab('description')}
                className={classNames(s.tabButton, {
                  [s.activeTab]: activeTab === 'description',
                })}
              >
                {t('galleryPage.mobDescription')}
              </button>
            </div>
          )}

          <div className={s.sectionImg}>
            {isSliderPage ? (
              windowWidth <= 650 ? (
                activeTab === 'photo' ? (
                  // Mobile
                  <div className={s.verticalImages}>
                    <img
                      className={s.img}
                      src={image.src}
                      alt={t(image.titleKey)}
                    />
                    <img
                      className={s.img}
                      src={
                        imageId === 16
                          ? '/images/altarOpen.png'
                          : '/images/sarcofagOpen.png'
                      }
                      alt={`Another slide for ID ${imageId}`}
                    />
                  </div>
                ) : (
                  <div className={s.description}>{t(image.descriptionKey)}</div>
                )
              ) : //Tablet
              windowWidth <= 900 ? (
                <div className={s.verticalImages}>
                  <img
                    className={s.img}
                    src={image.src}
                    alt={t(image.titleKey)}
                  />
                  <img
                    className={s.img}
                    src={
                      imageId === 16
                        ? '/images/altarOpen.png'
                        : '/images/sarcofagOpen.png'
                    }
                    alt={`Another slide for ID ${imageId}`}
                  />
                </div>
              ) : (
                // Desktop
                renderSlider()
              )
            ) : (
              <img
                className={s.img}
                src={displayedSrc}
                alt={t(image.titleKey)}
              />
            )}
          </div>

          <div className={s.sectionDesc}>
            {(!isSliderPage || windowWidth > 650) && (
              <>
                <h1 className={s.title}>{t(image.titleKey)}</h1>
                <div className={s.preTitle}>{t('galleryPage.description')}</div>
                <div className={s.description}>{t(image.descriptionKey)}</div>
              </>
            )}

            {hasVariants && (
              <ImageVariants
                baseColor={image.color}
                variants={image.variant}
                onVariantChange={setSelectedVariantIndex}
                selectedVariant={selectedVariantIndex}
              />
            )}

            <Button
              onClick={() => navigate('/gallery')}
              className={s.btn}
              text={t('galleryPage.return')}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GalleryPage;
